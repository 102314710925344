import moment from "jalali-moment";
export var momentfilter = {
    filters:{
        momentDate(date){
            return moment.unix(date).locale('fa').format('jYYYY/jMM/jDD');
        },
        momentHour(date){
            let hours = moment.unix(date).locale('fa').startOf('hour').fromNow();
            return moment.unix(date).locale('fa').calendar({
                sameDay: hours,
                lastDay: ' H:m [دیروز]',
                lastWeek: hours,
                sameElse: 'jYYYY/jMM/jDD'
            });
        },
    },
}