
<template>
  <v-carousel
              height="100%"
              cycle
              show-arrows-on-hover
              hide-delimiter-background
              delimiter-icon="mdi-minus"
              v-model="model">
    <v-carousel-item
        v-for="(item,i) in data"
        :key="i"

    >
      <v-sheet
          color="#1a1927"
          height="100%"
          tile
      >
        <v-row
            class="fill-height"
            align="center"
            justify="start"
        >

          <img :src="$store.state.file + item.image" style="border-radius: 8px;width: 100%"/>

        <div class="topimagecontent">
          <a :href="item.target" target="_blank" style="font-size: 22px;color: #e2f6f8;cursor: pointer" class="text-right ma-0">{{item.title}}</a>
          <br>
          <a :href="item.target" target="_blank" style="color:#c7edf0;cursor: pointer" class="text-right mb-2" >{{item.desc}}</a>
        </div>
          <div class="text-h2">

          </div>
        </v-row>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>


<script>
import axios from "axios";

export default {
  created() {
    axios.get(this.$store.state.api + 'slidersite').then(response=>{
      this.data = response.data.data;
    })
  },
  data: () => ({
    model: 0,
    data : null,
  }),
}
</script>

