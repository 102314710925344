<template>
  <div>


    <div class="topheader" >
      <router-link :to="{name:'Home'}" custom v-slot="{ navigate , href }">
      <a :href="href" role="link" @click="navigate"><p class="sitetitle" >مهران مدیا</p></a>
      </router-link>
      <v-fade-transition
      mode="out-in"
      >
      <div class="topcontentlink" v-if="renderComponent">

      <div v-if="item" class="d-flex justify-space-between align-center">
      <div class="d-flex align-center">

        <router-link :to="{name:'single',params:{id:item.id,title:item.title}}" >
      <div class="head-image-card" :style="{background : 'url('+ $store.state.file + item.image +')'}" style="z-index: 0;">
        <img v-if="item.image" class="head-image-card ma-0" :src="this.$store.state.smallthumbnail + item.image" >
      </div>
        </router-link>

        <div class="mt-11">
          <router-link :to="{name:'single',params:{id:item.id,title:item.title}}" custom v-slot="{navigate,href}">
      <a @click="navigate" :href="href" class="ma-0" style="color: #c7c7c7;font-size: 18px">{{item.title}}</a>
          </router-link>
      <p class="ma-0 mr-1" style="color: #65657a;font-size: 14px" >{{item.desc}}</p>
        </div>
      </div>
        <div class="mt-sm-16 mt-8 ">
          <p class="mb-1 mt-4 text-center" style="color: #65657a;font-size: 14px" v-if="item.createTime">{{item.createTime | momentHour}}</p>
          <p class="mb-1 mt-4 text-center" style="color: #65657a;font-size: 14px" v-else>بدون زمان</p>
          <router-link :to="{name:'single',params:{id:item.id,title:item.title}}" custom v-slot="{navigate,href}">
          <v-btn @click="navigate" :href="href" height="34" color="#2c414a" style="color: #aeaeae" class="d-block mx-auto pt-2">مشاهده</v-btn>
          </router-link>
        </div>
      </div>

    </div>
      </v-fade-transition>
    </div>

    <div v-if="importantcontent">

    <p class="mb-5 mr-2" style="font-size: 24px;color: #aeaeae">عناوین مهم</p>


      <div style="width: 100%;overflow: hidden;border-radius: 8px">

        <SliderSite></SliderSite>
      </div>

    </div>

    </div>
</template>

<script>
import SliderSite from "./SliderSite";
import axios from "axios";
import {momentfilter} from "../plugins/moment";

export default {
  name: 'App',
  mixins :[momentfilter],
  components : {
    SliderSite
  },
  created() {
    axios.get(this.$store.state.api+'homefeaturepost').then(response=>{
      this.data = response.data.data;
      if(this.data){
        this.randomIndex = Math.floor(Math.random() * this.data.length);
        this.item = this.data[this.randomIndex];
      }
    })
  },
  methods : {
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    changeHomefeaturepost(){
      setInterval(()=>{
        if(this.data){
          if(this.randomIndex == this.data.length-1){
            this.randomIndex = 0;
            this.item = this.data[this.randomIndex];
          } else {
            this.randomIndex++;
            this.item = this.data[this.randomIndex];
          }
        }

      },6500)
    }
  },
  mounted() {
    this.changeHomefeaturepost();
  },
  props : {
    importantcontent : {
      default : true,
    }
  },
  watch : {
    randomIndex(){
      this.forceRerender();
    }
  },
  data() {
    return {
      color: "#57c6d9",
      randomIndex : null,
      data : null,
      item : null,
      renderComponent : true,
    }
  },
};
</script>

<style lang="scss">
.topimage{
  background-size: 100%;
  background: url("../assets/images/examples/Rh.jpg") no-repeat center;
}

.sitetitle{
  font-size: 36px;
  color: #a0e1fd;
  cursor: pointer;
  text-shadow: 0 0 1.5px #0aa79e, 0 0 2.5px #0aa79e, 0 0 4px #0aa79e, 0 0 5px #228dff,
  0 0 8px #228dff, 0 0 10px #228dff, 0 0 12.5px #228dff, 0 0 20px #228dff;
}

@media only screen and (min-width: 960px) {
  .sitetitle{
    animation: neon2 1.5s ease-in-out infinite alternate;
  }
}

@keyframes neon2 {
  from {
    text-shadow: 0 0 5px #0aa79e, 0 0 10px #0aa79e, 0 0 15px #0aa79e, 0 0 20px #228dff,
    0 0 35px #228dff, 0 0 40px #228dff, 0 0 50px #228dff, 0 0 75px #228dff;
  }
  to {
    text-shadow: 0 0 2.5px #0aa79e, 0 0 5px #0aa79e, 0 0 7.5px #0aa79e, 0 0 10px #228dff,
    0 0 17.5px #228dff, 0 0 20px #228dff, 0 0 25px #228dff, 0 0 37.5px #228dff;
  }
}

.topheader{
  background: url("../assets/images/topimage.png") no-repeat;
  background-size: 100%;
  padding-top: 25px;
  padding-right: 15px;
  padding-left: 0px;
  padding-bottom: 40px;
  overflow: hidden;
}

@media only screen and (min-width: 960px) {
  .topheader{
    min-height: 285px;
    padding-top: 25px;
    padding-right: 40px;
    padding-left: 32px;
    padding-bottom: 40px;
  }
}

.head-image-card {
  width: 70px;
  height: 105px;
  border-radius: 5px;
  position: relative;
  background-size: cover;
  margin: 46.2px 10px 0 23.7px;
  cursor: pointer;
  transition: 0.2s all;
}

@media only screen and (min-width: 960px) {
  .head-image-card {
    margin: 46.2px 20px 0 23.7px;
  }
}


.head-image-card:before {
  content: "";
  position: absolute;
  width: 105%;
  height: 110%;
  z-index: -1 !important;
  background-image: inherit;
  background-size: cover;
  border-radius: 5px;
  filter: blur(12px);
  opacity: 0.6;
  left: 55%;
  bottom : 0;
  transform: translate(-50%, 10%);
}

.head-image-card:hover:before{
  opacity: 0.8;
}

.topimagecontent{
  position: absolute;
  top: 0;
  padding-right: 14px;
  padding-top: 36px;
}

@media only screen and (min-width: 600px) {
  .topimagecontent{
    padding-right: 70px;
    padding-top: 36px;
  }

}

.topcontentlink:hover{
  .head-image-card:before {
    opacity: 0.9;
  }
}

.topheader .fade-transition-enter-active {
  transition: all 3s ease !important;
}

</style>

